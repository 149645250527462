import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Dumbbell Deadlifts 12-12-12\\@60% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`20-KB SDHP (53/35)`}</p>
    <p>{`15-K2E’s`}</p>
    <p>{`10-Burpee Over KB`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`2 x Tabata Row for Calories, 16 rounds of :20 on :10 off`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Open Workout 17.2 will be released live tonight at 8:00pm!  Tune in
at Games.CrossFit.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts March 4th. Email Eric at
fallscitystrength\\@gmail for more info.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      